.cameraWrapper {
  background: white;
  padding: 5px;
  border-radius: 20px;
  border: 1px solid rgba(243, 243, 243, 0.6);
  margin-bottom: 9px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapleftflight {
  width: 100%;
  background-color: rgba(131, 131, 131, 1);
  height: 100%;
  border-radius: 20px;
  border: 1px solid rgba(131, 131, 131, 0.6);
  padding: 15px;
}

.containerTimer {
  position: absolute;
  top: 20px;
  font-size: 25px;
  right: 20px;
  background: rgba(255, 255, 255, 0.588);
  padding: 0px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}

.recordAnimation {
  animation: SplashAnimation 2s infinite;
}

@keyframes SplashAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.se-wrapper-inner, .se-wrapper-wysiwyg, .sun-editor-editable {
  word-break: break-all;
}