.hightlightred{
    background-color: rgba(236, 100, 75, 1) !important;
}
.hightlightyellow{
    background-color: rgba(255, 255, 126, 1) !important;
}


.intputmedicine {
    width: 250px;
    margin-right: 15px !important;
}