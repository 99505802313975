.SIDCard {
    &:hover {
        cursor: pointer;
        box-shadow: 0 0 0 3px #009688 !important;
        border: none;
    }
    position: relative;
    width: 245px;
    background-color: #e6f9ff;
    border-radius: 8px;
    border: solid 1px #bfbfbfba;
    padding: 12px;
    hr {
        margin-top: 8px !important;
        margin-bottom: 5px !important;
    }
    .SIDSeq {
        position: absolute;
        right: 10px;
        font-size: 35px;
        color: #a9a9a999;
        top: 0;
        background-color: #e6f9ff;
        padding-left: 10px;
    }
    .SIDTitle {
        color: #009688;
        font-weight: bold;
    }
}