.morris-hover {
	position:absolute;
	z-index:1;
}

.morris-hover.morris-default-style .morris-hover-row-label {
	font-weight:bold;
	margin:0.25em 0
}
.morris-hover.morris-default-style .morris-hover-point {
	white-space:nowrap;
	margin:0.1em 0
}
.morris-hover.morris-default-style {
    border-radius: 4px;
    padding: 10px 12px;
    color: #666;
    background:#63676b;
    border: none;
    color: #fff!important ;
    box-shadow: none;
    font-size:14px;
}
 .morris-hover-point {
      color: rgba(257, 257, 257, 0.8)!important ;
   }
