/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
p {
  /* color: green; */
  /* text-align: center; */
} 

.ant-input{
  border: 1px solid #d9d9d9 !important;
}

.ant-statistic-content{
  font-size: 17px !important;
}

.datepicker-170w{
  width: 170px !important;
}



.ant-picker{
  width: 125px ;
}

 .ant-picker-range{
  width: 100% !important ;
  /* height: 100% !important; */
}

.modalPreview  .ant-modal-content{
  background-color: transparent;
}
.modalPreview  .ant-modal-body{
  background-color: transparent;
}
.ant-input-affix-wrapper{
  border-radius: 8px !important;
}

.btn-scale{
  transform: scale(0.9);
}

.required-field::after {
  content: "*";
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 15px;
  font-family: "SimSun, sans-serif";
}

.react-datetime-picker {
  border-radius: 8px !important;
  border: 1px solid #d9d9d9 !important;
  font-family: "Arimo", sans-serif;
  font-size: 13px !important;
  height: 30px !important;
}

.react-datetime-picker .react-datetime-picker__button {
  padding: 0 4px !important;
}

.react-datetime-picker .react-datetime-picker__inputGroup {
  display: flex;
  align-items: center;
  font-family: "Arimo", sans-serif !important;
  font-size: 13px !important;
}

.modal-header, .custom-hr, .custom-hr-no-margin {
  border-bottom: 2px solid rgb(149, 149, 149);
  margin: 10px 0px;
}

.custom-hr-no-margin {
  width: 100%;
}

.custom-hr {
  width: 100%;
  margin: 20px 0px !important;
}
.fit-content {
  width: fit-content;
}
label {
  margin-bottom: 0.2rem !important;
}

.custom-hr-doted {
  width: 100%;
  border-bottom: 2px dotted rgb(149, 149, 149);
  margin: 20px 0px !important;
}

.ant-select-selection-item {
  border-radius: 8px !important;
}

.ant-popover-inner {
  border-radius: 8px;
}

.ant-picker-panel-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ant-radio-button-wrapper:first-child {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.ant-radio-button-wrapper:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

@keyframes fadeInTop {
  0% {
    opacity: .5;
    transform: translateY(-10px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(0px);
  }
  75% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.not-hover:hover {
  color: #000 !important;
  background-color: #fff !important;
}

.not-hover:hover > td {
  background-color: #fff !important;
}