.photocontainer {
  position: relative;
  width: 75%;
}

.hasphoto .ant-upload-text {
  display: none;
}

.hasphoto > span {
  width: 80px !important;
  position: absolute;
  right: -50px;
  top:0;
}

.hasphoto .ant-upload {
    width: 40px;
}
.hasphoto .anticon {
  font-size: 20px !important;
}
