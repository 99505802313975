@mixin QMSPositionMargin {
    position: relative;
    margin: 20px auto;
}
@mixin QMSDisplayFlex($flx: row, $jst: center) {
    display: flex;
    flex-direction: $flx;
    justify-content: $jst;
}
@mixin QMSPadding($valTB: 10px, $valLR: 10px) {
    padding: $valTB $valLR;
}
@mixin QMSText($trs: uppercase, $wei: bold) {
    text-transform: $trs;
    font-weight: $wei;
}
@mixin QMSBorder($pos: "", $val: 1px, $clr: black, $rad: 5px) {
    border#{$pos}: $val solid $clr;
    border-radius: $rad;
}
@mixin QMSDisplayItem {
    > span {
        &:first-child {
            width: 50px;
        }
        &:last-child {
            width: 100px;
        }
    }
}
@mixin FullWidthTextCenter() {
    width: 100%;
    text-align: center;
}
.QMS {
    width: 100vw;
    height: 100vh;
    @include QMSDisplayFlex();
    flex-wrap: wrap;
    @include QMSPadding();
    .QMSContainer {
        @include QMSBorder($rad: 0);
        position: relative;
        width: 100%;
        height: 100%;
        .QMSHeaderContainer {
            @include QMSPositionMargin;
            h5 {
                @include QMSText();
                text-align: center;
            }
            .QMSAdditionalInfo {
                position: absolute;
                right: 20px;
                bottom: -20px;
                @include QMSDisplayFlex($flx: column);
                align-items: end;
                line-height: 20px;
            }
        }
        .QMSHr {
            @include QMSBorder($pos: -bottom, $rad: 0);
            margin: auto 10px;
        }
    }
    .QMSPatient {
        margin: 20px 10px 0 10px;
        height: 100%;
        .QMSHeaderCurrent, .QMSHeaderWaiting, .QMSPatientCurrent, .QMSPatientWaiting {
            color: white;
            width: 100%;
            height: 50px;
            align-items: center;
            @include QMSBorder($val: 0);
            @include QMSDisplayFlex();
            @include QMSPadding(0, 20px);
            @include QMSDisplayFlex(row, space-between);
            @include QMSDisplayItem();
        }
        .QMSHeaderCurrent {
            background-color: #029e0e;
            @include QMSText();
        }
        .QMSHeaderWaiting {
            background-color: #2c78e4;
            margin-top: 20px;
            @include QMSText();
        }
        .QMSPatientCurrent, .QMSPatientWaiting {
            @include QMSDisplayItem();
            @include QMSText($trs: normal);
        }
        .QMSPatientCurrent {
            @include QMSBorder($rad: 0);
            color: #d6514e !important;
        }
        #QMS-patient-waiting {
            height: calc(100% - 250px);
        }
        .QMSPatientWaiting {
            color: #3c3c3c !important;
            @include QMSBorder($val: 0, $rad: 0);
        }
        .Odd {
            background-color: #cccccc;
        }
        .Even {
            background-color: #dddddd;
        }
        .AutoScrollY {
            overflow-y: auto;
            height: calc(100% - 15px);
        }
    }
    .QMSPatientInfo {
        @include QMSDisplayFlex($flx: column);
        @include FullWidthTextCenter();
        line-height: 20px;
        .QMSPatientName {
            @include FullWidthTextCenter();
            @include QMSText();
        }
    }
}