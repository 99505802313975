
.center {
	margin: 0 auto;
	text-align: center;
}

.spacer {
	margin-top: 6rem;
}
.rect{
	position: absolute;
}
/* barcode container */
.anim-box {
	position: relative;
	animation-play-state: running;
	padding: 22px 25px;
	margin: 0px;
	transition: transform .6s ease-out;
}

/* adds the 4 corners */
.anim-box:before, .anim-box:after, .anim-box>:first-child:before, .anim-box>:first-child:after {
	position: absolute;
	width: 10%; height: 13%;
	border-color: white; 
	border-style: solid;
	content: ' ';
	animation-play-state: running;
}

/* top left corner */
.anim-box:before {
	top: 0; left: 0;
	border-width: 2px 0 0 2px;
	border-color: #d3d6d8;
}

/* top right corner */
.anim-box:after {
	top: 0; right: 0;
	border-width: 2px 2px 0 0;
	border-color: #d3d6d8;
}

/* bottom right corner */
.anim-box>:first-child:before {
	bottom: 0; right: 0;
	border-width: 0 2px 2px 0;
	border-color: #d3d6d8;
}

/* bottom left corner */
.anim-box>:first-child:after {
	bottom: 0; left: 0;
	border-width: 0 0 2px 2px;
	border-color: #d3d6d8;
}

/* barcode bars */
.anim-item {
	display: inline-block;
	background-color: white;
	height: 100px;
	animation-play-state: running;
}

.anim-item-sm {
	width: 2px;
	margin-right: 3px;
}

.anim-item-md {
	width: 3px;
	margin-right: 2px;
}

.anim-item-lg {
	width: 5px;
	margin-right: 5px;
}

/* grow on hover */
/* .anim-box:hover {
	transform: scale(1.5, 2);
} */

/* .anim-box:hover .scanner {
  animation-play-state: running;
} */

/* animated laser beam */
.scanner {
	width: 100%;
	height: 3px;
	background-color: red;
  opacity: 0.7;
  position:relative;
  box-shadow: 0px 0px 8px 10px rgba(170, 11, 23, 0.49);
  top:50%;
  animation-name: scan;
  transform: scale(1, 2);
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
  position: absolute;
  left: 0;
}

@keyframes scan {
  0% {
    box-shadow: 0px 0px 8px 10px rgba(170, 11, 23, 0.49);
    top: 50%;
  }  
  25% {
    box-shadow: 0px 6px 8px 10px rgba(170, 11, 23, 0.49);
    top: 5px;
  }
  75% {
    box-shadow: 0px -6px 8px 10px rgba(170, 11, 23, 0.49);
    top: 98%;
  }
}