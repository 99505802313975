.pagenotfoundwrappers{
  width: 100%;
  display:flex;
  justify-content: center;
}
.svgfortemp {
    /* position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -400px; */
    align-items: center;
    width: 48%;
  }
  .message-box {
   
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .message-box h1 {
    font-size: 60px;
    line-height: 46px;
    margin-bottom: 40px;
  }
  .link-button {
    margin-top: 40px;
  }
  .link-button  {
    margin-top:15px;
    background: #68c950;
    padding: 8px 25px;
    border-radius: 4px;
    color: #FFF;
    font-weight: bold;
    font-size: 12px;
    transition: all 0.3s linear;
    cursor: pointer;
    text-decoration: none;
    margin-right: 10px
  }
  .link-button:hover {
    background: #5A5C6C;
    color: #fff;
  }
  
  #Polygon-1 , #Polygon-2 , #Polygon-3 , #Polygon-4 , #Polygon-4, #Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
  }
  #Polygon-2 {
    animation-delay: .2s; 
  }
  #Polygon-3 {
    animation-delay: .4s; 
  }
  #Polygon-4 {
    animation-delay: .6s; 
  }
  #Polygon-5 {
    animation-delay: .8s; 
  }
  
  @keyframes float {
      100% {
      transform: translateY(20px);
    }
  }
  @media (max-width: 450px) {
   
  }
